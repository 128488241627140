import { AUTH_LOGIN } from "react-admin";
import { authenticate } from "./service/service";
import LOGIN_URL from "./constants";
import axios from "axios";

// export const (type, params) => {
//   if (type === AUTH_LOGIN) {
//     const { username, password } = params;
//     if (username === "admin" && password === "admin") {
//       localStorage.setItem("username", username);
//       return Promise.resolve();
//     } else {
//       throw new Error("Wrong username/password!");
//     }
//   }
//   return Promise.resolve();
// };

export const authProvider = {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const url = "/back-office/api/auth/login";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const data = {
      username,
      password,
    };
    return axios
      .post(url, data, { headers })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          console.log("errrororor");
          throw new Error(response.statusText);
        }
        return response;
      })
      .then((auth) => {
        localStorage.setItem("token", auth.data.token);
      })
      .catch((error) => {
        throw new Error("Network error");
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};
